import React from 'react'

class Solution extends React.Component {
  render () {
    return (
      <section id='features-1'>
        <div className='row section-intro'>
          <div className='col-twelve with-bottom-line'>
            <h5>Franchise</h5>
            <h1>What is RoboChef Franchise?</h1>
            <p className='lead'>RoboChef Franchise Advantages.</p>
          </div>
        </div>

        <div className='row features-content'>
          <div className='features-list block-1-3 block-s-1-2 block-tab-full group'>
            <div className='bgrid feature'>
              <span className='icon'><i className='icon-feature' /></span>
              <div className='service-content'>
                <br></br>
                <br></br>
                <br></br>
                <h2 className='h05'>Lower Real-estate Cost</h2>
              </div>
            </div>

            <div className='bgrid feature'>
              <span className='icon'><i className='icon-feature' /></span>
              <div className='service-content'>
                <img src="auto.png" style={{backgroundColor: "white", borderRadius: "25px"}} height="200"  width="200"></img>
              </div>
            </div>

            <div className='bgrid feature'>
              <span className='icon'><i className='icon-feature' /></span>
              <div className='service-content'>
                <br></br>
                <br></br>
                <br></br> 
                <h2 className='h05'>Expansion Oppurtunities</h2>
              </div>
            </div>

            <div className='bgrid feature'>
              <span className='icon'><i className='icon-feature' /></span>
              <div className='service-content'>
                <img src="images/overhead.png" style={{backgroundColor: "white", borderRadius: "25px"}} height="200"  width="200"></img>
              </div>
            </div>

            <div className='bgrid feature'>
              <span className='icon'><i className='icon-feature' /></span>
              <div className='service-content'>
                <br></br>
                <br></br><br></br>
                <h2 className='h05'>Save Overhead Cost.</h2>
              </div>
            </div>

            <div className='bgrid feature'>
              <span className='icon'><i className='icon-feature' /></span>
              <div className='service-content'>
                <img src="auto.png" style={{backgroundColor: "white", borderRadius: "25px"}} height="200"  width="200"></img>
              </div>
            </div>

            <div className='bgrid feature'>
              <span className='icon'><i className='icon-feature' /></span>
              <div className='service-content'>
                <br></br>
                <br></br>
                <h2 className='h05'>Chef-Less Restaurant</h2>
              </div>
            </div>

            <div className='bgrid feature'>
              <span className='icon'><i className='icon-feature' /></span>
              <div className='service-content'>
                <img src="images/highreturns.png" style={{backgroundColor: "white", borderRadius: "25px"}} height="200"  width="200"></img>
              </div>
            </div>

            <div className='bgrid feature'>
              <span className='icon'><i className='icon-feature' /></span>
              <div className='service-content'>
                <br></br>
                <br></br>
                <h2 className='h05'>End-to-End Support & High Returns.</h2>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default Solution
