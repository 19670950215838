import React from 'react'

class Contact extends React.Component {
  render () {
    return (
      <section id="faq">

       <div className="overlay"></div>

 		<div className="row narrow section-intro with-bottom-sep animate-this">
    		<div className="col-twelve">
    			<h1>Get In Touch.</h1>

    			<p className="lead">It's Easy to get along with RoboChef. It can cook you tasty food with consistent taste. For Franchise information, Contact here !</p>
    		</div>
    	</div> 

    	<div className="row contact-content">

    		<div className="col-seven tab-full animate-this">

				{/* <div className='mailmunch-forms-widget-814226'></div> */}
				<iframe src="https://docs.google.com/forms/d/e/1FAIpQLScd8xygLa61pbKmlzH7JsfsAOKKt_oeO7yjzsKFewPV6opvew/viewform?embedded=true" width="100%" height="980" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>

    			{/* <h5>Send Us A Message</h5>

             <form name="contactForm" id="contactForm" method="post" action="">

                <div className="form-field">
  					   <input name="contactName" type="text" id="contactName" placeholder="Name" defaultValue="" minlength="2" required="" ></input>
                </div>

                <div className="row">
                  	<div className="col-six tab-full">
                  		<div className="form-field">
                  			<input name="contactEmail" type="email" id="contactEmail" placeholder="Email" defaultValue="" required="true" ></input>
                  		</div>
 		            </div>
 	            	<div className="col-six tab-full">
 	            		<div className="form-field">
 	            			<input name="contactSubject" type="text" id="contactSubject" placeholder="Subject" defaultValue=""></input>
 	                  </div>
 		            </div>
                </div>

                <div className="form-field">
 	              	<textarea name="contactMessage" id="contactMessage" placeholder="message" rows="10" cols="50" required=""></textarea>
 	            </div>

                <div className="form-field">
                   <button className="submitform">Submit</button>

                   <div id="submit-loader">
                      <div className="text-loader">Sending...</div>
        			      <div className="s-loader">
 							  	<div className="bounce1"></div>
 							  	<div className="bounce2"></div>
 							  	<div className="bounce3"></div>
 							</div>
 						</div>
                </div>

       		</form> 

            
             <div id="message-warning"></div>

             
       		<div id="message-success">
                <i className="fa fa-check"></i>Your message was sent, thank you!<br></br>
       		</div> */}
			
          </div> 

          <div className="col-four tab-full contact-info end animate-this">
			<br></br><br></br><br></br><br></br>
          	<h5>Contact Information</h5>

          	<div className="cinfo">
 	   			<h6>Where to Find Us</h6>
 	   			<p>
					Kanniyappan Street,<br /> Ramapuram, <br />Chennai, India, 600 089
 	            </p>
 	   		</div> 

 	   		<div className="cinfo">
 	   			<h6>Email Us At</h6>
 	   			<p>
            <a href="mailto:robotickitchenindia@gmail.com">robotickitchenindia@gmail.com</a> <br/>
             <a href="mailto:info@admatic.in">info@admatic.in</a>
 				   </p>
 	   		</div>

 	   		<div className="cinfo">
 	   			<h6>Call Us At</h6>
 	   			<p>
            <a href="tel:+919789968765">+91 97899 68765</a><br/>
            <a href="tel:+919940432545">+91 99404 32545</a><br/>
 				   </p>
 	   		</div>
				
				<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d242.94591160122502!2d80.17616770833074!3d13.02710031410195!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a5260da01d81ecb%3A0x7cbed9499b53d7b2!2sKanniyappan%20St%2C%20Shanthi%20Nagar%2C%20Ramapuram%2C%20Chennai%2C%20Tamil%20Nadu%20600087!5e0!3m2!1sen!2sin!4v1586167515005!5m2!1sen!2sin" width="100%" height="100%" frameborder="0" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
          </div>
    	</div>
 	</section>
    )
  }
}

export default Contact
