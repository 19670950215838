import React from 'react'
import Helmet from 'react-helmet'
import { withPrefix } from "gatsby"

class Intro extends React.Component {
  render () {
    let title,finalT, slug='', heroText, heroImage, aboutText, aboutText2, url,cities=[],citySeo,aweKey=[],locations=[],locSeo
    cities = ['Chennai','India']
    locations = ['Velachery', 'Adyar']
    aweKey = ['Robotic Restaurant ', 'Automated Kitchen', 'RoboChef','Cloud Kitchen', 'Connected Kitchen' ,'Cooking Robot Stir', 'Mechanical Chef',  'Robotic Chef ', 'Robotic Restaurant', 'Automated Restaurant','cooking robot arms', 'Chef bot', 'Robot that cooks','food robot']
    if (this.props.data === undefined) {
      title = 'RoboChef Cloud Kitchen'
      heroText = 'RoboChef is Inviting Franchise Partners across the Globe for the Cloud Kitchen Model'
      slug = ''
      citySeo = ""
      locSeo = ""
      for (let i = 0; i < 2; i++) {
        citySeo = citySeo + "First Robotic Kitchen in " + cities[i] + ". " + "Fully Automated Restaurent in " + cities[i] + ". "
      }
      for (let i = 0; i < 2; i++) {
        locSeo = locSeo + "First Robotic Kitchen in " + locations[i] + ". " + "Fully Automated Restaurent in " + locations[i] + ". "
      }
      for (let j = 0; j < 12; j++) {
      for (let i = 0; i < 2; i++) {
        citySeo = citySeo + aweKey[j] + " in " + cities[i] + ". "
      }
      slug = slug + aweKey[j] + " " 
    }
      aboutText2 = 'The RoboChef - The Robotic Kitchen aka the Automated cooking-bot is completely an interchangeable option in this buzz world. This machine cooks/serves a delicious meal, with the same consistent taste. This even comes along with a customizable meal with varied choices. This machine operates with high speed and accuracy without any distortion in aroma & savor.'
      aboutText = citySeo + 'Completely operates through IoT and Machine Learning. India made Robot, that can cook on it\'s own.' + locSeo
      aboutText2 = aboutText2 + aboutText
    }
    finalT = title + " India | Automated Robotic Kitchen in Chennai"
    url = 'https://robotickitchen.in'
    return (
      <section id='intro-1'>
        <Helmet>  
          <title>{finalT}</title>
          <meta name='description' content={aboutText} />
          <meta name='image' content={heroImage} />
          <meta name='author' content='Admatic Solutions' />
          <meta name="viewport" content="width=device-width, initial-scale=1" />

          <link rel='canonical' href={url} />

          <meta property='og:locale' content='en_US' />
          <meta property='og:type' content='business.business' />
          <meta property='og:title' content={title} />
          <meta property='og:site_name' content='https://robotickitchen.in | First Fully Automated Robotic Kitchen in India'/>
          <meta property='og:description' content={slug} />
          <meta property='og:url' content={url} />
          <meta property='og:image' content='https://robotickitchen.in/favicons/android-chrome-512x512.png' />

          <meta property='business:contact_data:street_address' content='#67, 2c, Devadaya, Gandhinagar, 1st Main Road' />
          <meta property='business:contact_data:locality' content='Chennai' />
          <meta property='business:contact_data:region' content='TamilNadu' />
          <meta property='business:contact_data:postal_code' content='600020' />
          <meta property='business:contact_data:country_name' content='India' />
        </Helmet>
        
        
        <video playsinline poster="images/robochef_co.jpg" autoplay="play" height="100%" width="100%" muted="muted" disablePictureInPicture="true" loop>
            {/* <source src="images/1.mp4" type="video/mp4" /> */}
        </video>
      <div className='shadow-overlay' />
        <div className='intro-content'>
  
          <div className='row'>
          
            <div className='col-twelve'>
            <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
              <h5>{heroText}</h5>
              <br/>
              <h1>{title}</h1>

              <a className='button stroke smoothscroll' href='#features-1' title=''>Learn More</a>
              <br/><br/><br/>

           <div class='video-link'>
	   				<a href="#video-popup"><img src="images/play-button.png" alt="" /></a>
	   			</div>


          <h5> Inviting Partners </h5>
          <br/><br/><br/><br/><br/><br/><br/><br/>
          
            </div>
            
          
        </div>
        </div>

        <div id='video-popup' className='popup-modal mfp-hide'>
          <div className='fluid-video-wrapper'>
            <iframe width='100%' height='400' src='https://www.youtube.com/embed/jgMFj94Y4V4' frameBorder='0' allow='accelerometer; autoplay; encrypted-media; gyroscope;' allowFullScreen />
          </div>
          <a className='close-popup'>Close</a>
        </div>

      </section>
    )
  }
}

export default Intro
