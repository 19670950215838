import React from 'react'

import Layout from '../components/flayout'
import Franchise from '../components/index/franchise'
import Intro from '../components/index/franchiseIntro'
import Contact from '../components/index/contact'

export default ({ data }) => {
  return (
    <Layout>
      <Intro />
      <Franchise />
      <Contact />
    </Layout>
  )
}
